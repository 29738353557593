import { useNavigate, useParams } from "react-router-dom";
import './styles.css';
import { useCallback, useEffect, useState } from "react";
import { Product } from "../../interfaces/product";
import Header from "../../components/header";
import { BsArrowBarDown, BsArrowLeft } from "react-icons/bs";
import { supabaseService } from "../../services/supabase";

function Details() {
  const [product, setProduct] = useState<Product | null>();
  const [tries, setTries] = useState<number>(0);
  let { id } = useParams();
  const navigate = useNavigate();

  const getProduct = useCallback(async () => {
    if (id) {
      const foundProduct = await supabaseService().getProductById(id as unknown as number, navigator.language === 'pt-BR');
      if (foundProduct)
        setProduct(foundProduct);
    }
  }, [id]);

  function Back() {
    navigate(-1);
  }

  function OpenAddOrLinK(magnetlink: string) {
    if (tries === 3)
      window.open(magnetlink, "_blank")
    else {
      var adLink = product?.type.descriptionenus === 'Movie' ? process.env.REACT_APP_MOVIES_AD_LINK : process.env.REACT_APP_GAMES_AD_LINK;
      window.open(adLink, "_blank")
      setTries(tries + 1);
    }
  }

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  return (
    <div>
      <Header />
      <BsArrowLeft className='back-btn' size={30} onClick={() => Back()} />
      <div className='details-page'>
        <h1>{product?.labelptbr ?? product?.labelenus}</h1>
        <div className='product-info'>
          <div>
            <img className='poster-details' src={product?.image} alt="Poster" />
          </div>
          <div className='info-details'>
            <p>IMDB:{product?.imdb}</p>
            <p>Ano:{product?.year}</p>
            <p>{product?.synopsisptbr ?? product?.synopsisenus}</p>
            <div className='categories'>
              {
                product?.category.map((cat) =>
                  <span key={cat.descriptionptbr ?? cat.descriptionenus}>{cat.descriptionptbr ?? cat.descriptionenus}</span>
                )
              }
            </div>
            {
              product?.magnetlink720p ? <button className='download-btn' onClick={() => OpenAddOrLinK(product?.magnetlink720p as string)}><BsArrowBarDown />{tries < 3 ? navigator.language === 'pt-BR' ? ' Desbloquear 720p' : ' Unlock 720p' : ' 720p'}</button> : <></>
            }
            {
              product?.magnetlink1080p ? <button className='download-btn' onClick={() => OpenAddOrLinK(product?.magnetlink1080p as string)}><BsArrowBarDown />{tries < 3 ? navigator.language === 'pt-BR' ? ' Desbloquear 1080p' : ' Unlock 1080p' : ' 1080p'}</button> : <></>
            }
            {
              product?.magnetlink2160p ? <button className='download-btn' onClick={() => OpenAddOrLinK(product?.magnetlink2160p as string)}><BsArrowBarDown />{tries < 3 ? navigator.language === 'pt-BR' ? ' Desbloquear 2160p' : ' Unlock 2160p' : ' 2160p'}</button> : <></>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;