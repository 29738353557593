import { useEffect, useState } from 'react';
import './styles.css';
import { ProductBasics } from '../../interfaces/product';
import CatalogSquare from '../catalogSquare';
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { supabaseService } from '../../services/supabase';
import { useSearch } from '../../hooks/useSearch';

function Catalog() {
  const { searchText } = useSearch();
  
  const [movies, setMovies] = useState<ProductBasics[]>([]);
  const [pageMovies, setPageMovies] = useState<number>(1);
  const [totalNumberOfMovies, setTotalNumberOfMovies] = useState<number>(0);

  const [games, setGames] = useState<ProductBasics[]>([]);
  const [pageGames, setPageGames] = useState<number>(1);
  const [totalNumberOfGames, setTotalNumberOfGames] = useState<number>(0);

  const [tvShows, setTvShows] = useState<ProductBasics[]>([]);
  const [pageTvShows, setPageTvShows] = useState<number>(1);
  const [totalNumberOfTvShows, setTotalNumberOfTvShows] = useState<number>(0);
  
  const pageSize = 6;

  useEffect(() => {
    const searchFunction = async () => {
      const totalGames = await supabaseService().getTotalNumberOfProducts('PC Game', navigator.language === 'pt-BR', searchText ?? '');
      const totalMovies = await supabaseService().getTotalNumberOfProducts('Movie', navigator.language === 'pt-BR', searchText ?? '');
      const totalTvShows = await supabaseService().getTotalNumberOfProducts('TV Show', navigator.language === 'pt-BR', searchText ?? '');

      const game = await supabaseService().getPCGames(pageSize, 0, navigator.language === 'pt-BR', searchText ?? '');
      const movie = await supabaseService().getMovies(pageSize, 0, navigator.language === 'pt-BR', searchText ?? '');
      const tvShow = await supabaseService().getTvShows(pageSize, 0, navigator.language === 'pt-BR', searchText ?? '');

      setTotalNumberOfGames(totalGames);
      setTotalNumberOfMovies(totalMovies);
      setTotalNumberOfTvShows(totalTvShows);

      setMovies(movie);
      setGames(game);
      setTvShows(tvShow);

      setPageMovies(1);
      setPageGames(1);
      setPageTvShows(1);
    };

    searchFunction();
    
  }, [searchText]);

  useEffect(() => {
    const getMovies = async () => {
      const movie = await supabaseService().getMovies(pageSize, (pageMovies - 1) * pageSize, navigator.language === 'pt-BR', searchText ?? '');
      setMovies(movie);
    };

    getMovies();
  // eslint-disable-next-line
  }, [pageMovies]);

  useEffect(() => {
    const getGames= async () => {
      const game = await supabaseService().getPCGames(pageSize, (pageGames - 1) * pageSize, navigator.language === 'pt-BR', searchText ?? '');
      setGames(game);
    };

    getGames();
  // eslint-disable-next-line
  }, [pageGames]);

  useEffect(() => {
    const getTvShows= async () => {
      const tvShow = await supabaseService().getTvShows(pageSize, (pageTvShows - 1) * pageSize, navigator.language === 'pt-BR', searchText ?? '');
      setTvShows(tvShow);
    };

    getTvShows();
  // eslint-disable-next-line
  }, [pageTvShows]);

  return (
    <div className='catalog'>
      {
        navigator.language === 'pt-BR' ?
        <h3>Bem vindo! Aqui você encontra os melhores títulos de suas franquias favoritas, explore nosso catálogo!</h3>
        :
        <h3>Welcome! Here you'll find the best titles from your favorite franchises. Explore our catalog!</h3>
      }
      {
        movies.length > 0 ?
        <>
          <h2>{navigator.language === 'pt-BR' ? 'Filmes' : 'Movies'}</h2>
          <div className='list'>
            {movies.map((movie) => 
              <CatalogSquare product={movie} key={movie.id} />
            )}
          </div>
          <div className='next-back-buttons'>
            <BsArrowLeft
              className={'btn' + (pageMovies === 1 ? ' disabled' : '')}
              size={30}
              onClick={() => pageMovies === 1 ? () => {} : setPageMovies(pageMovies - 1)} />
            <span className='page-marker'>{navigator.language === 'pt-BR' ? 'Página ' + pageMovies : 'Page ' + pageMovies}</span>
            <BsArrowRight
              className={'btn ' + (pageMovies * pageSize <= totalNumberOfMovies ? '' : ' disabled')}
              size={30}
              onClick={() => pageMovies * pageSize <= totalNumberOfMovies ? setPageMovies(pageMovies + 1) : () => {}} />
          </div>
        </>
        :
        <></>
      }
      {
        tvShows.length > 0 ?
        <>
          <h2>{navigator.language === 'pt-BR' ? 'Séries' : 'TV Shows'}</h2>
          <div className='list'>
            {tvShows.map((tvShow) => 
              <CatalogSquare product={tvShow} key={tvShow.id} />
            )}
          </div>
          <div className='next-back-buttons'>
            <BsArrowLeft
              className={'btn' + (pageTvShows === 1 ? ' disabled' : '')}
              size={30}
              onClick={() => pageTvShows === 1 ? () => {} : setPageTvShows(pageTvShows - 1)} />
            <span className='page-marker'>{navigator.language === 'pt-BR' ? 'Página ' + pageTvShows : 'Page ' + pageTvShows}</span>
            <BsArrowRight
              className={'btn ' + (pageTvShows * pageSize <= totalNumberOfTvShows ? '' : ' disabled')}
              size={30}
              onClick={() => pageTvShows * pageSize <= totalNumberOfTvShows ? setPageTvShows(pageTvShows + 1) : () => {}} />
          </div>
        </>
        :
        <></>
      }
      {
        games.length > 0 ?
        <>
          <h2>{navigator.language === 'pt-BR' ? 'Jogos de Computador' : 'PC Games'}</h2>
          <div className='list'>
            {games.map((game) => 
              <CatalogSquare product={game} key={game.id} />
            )}
          </div>
          <div className='next-back-buttons'>
            <BsArrowLeft
              className={'btn' + (pageGames === 1 ? ' disabled' : '')}
              size={30}
              onClick={() => pageGames === 1 ? () => {} : setPageGames(pageGames - 1)} />
            <span className='page-marker'>{navigator.language === 'pt-BR' ? 'Página ' + pageGames : 'Page ' + pageGames}</span>
            <BsArrowRight
              className={'btn ' + (pageGames * pageSize <= totalNumberOfGames ? '' : ' disabled')}
              size={30}
              onClick={() => pageGames * pageSize <= totalNumberOfGames ? setPageGames(pageGames + 1) : () => {}} />
          </div>
        </>
        :
        <></>
      }
    </div>
  );
}

export default Catalog;
