import { ReactNode, createContext, useContext, useState } from "react";

interface SearchProviderProps {
  children: ReactNode;
}

interface SearchContextData {
  search(text: string): Promise<void>;
  searchText: string | undefined;
}

const SearchContext = createContext<SearchContextData>({} as SearchContextData);

export function SearchProvider({ children }: SearchProviderProps): JSX.Element {
  const [searchText, setSearchText] = useState<string>();

  const search = async (text: string) => {
    setSearchText(text);
  }

  return (
    <SearchContext.Provider value={{ search, searchText }}>
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch(): SearchContextData {
  const context = useContext(SearchContext);

  return context;
}