import { createClient } from '@supabase/supabase-js';
import { Product, ProductBasics } from '../interfaces/product';

interface supabaseServiceData {
  getMovies(take: number, skip: number, isBrazilianPortuguese: boolean, searchText: string): Promise<ProductBasics[]>;
  getPCGames(take: number, skip: number, isBrazilianPortuguese: boolean, searchText: string): Promise<ProductBasics[]>;
  getTvShows(take: number, skip: number, isBrazilianPortuguese: boolean, searchText: string): Promise<ProductBasics[]>;
  getTotalNumberOfProducts(type: string, isBrazilianPortuguese: boolean, searchText: string): Promise<number>;
  getProductById(id: number, isBrazilianPortuguese: boolean): Promise<Product | null>;
}
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL ?? '', 
  process.env.REACT_APP_SUPABASE_ANON_KEY ?? ''
)

export const supabaseService = () : supabaseServiceData => {
  async function getMovies(take: number, skip: number, isBrazilianPortuguese: boolean, searchText: string): Promise<ProductBasics[]> {
    return getProductsBasics(take, skip, isBrazilianPortuguese, 'Movie', searchText);
  }

  async function getPCGames(take: number, skip: number, isBrazilianPortuguese: boolean, searchText: string): Promise<ProductBasics[]> {
    return getProductsBasics(take, skip, isBrazilianPortuguese, 'PC Game', searchText);
  }

  async function getTvShows(take: number, skip: number, isBrazilianPortuguese: boolean, searchText: string): Promise<ProductBasics[]> {
    return getProductsBasics(take, skip, isBrazilianPortuguese, 'TV Show', searchText);
  }

  async function getProductsBasics(take: number, skip: number, isBrazilianPortuguese: boolean, type: string, searchText: string): Promise<ProductBasics[]> {
    return new Promise((resolve) => {
      let execution = supabase
        .from("product")
        .select(isBrazilianPortuguese ?
          'id, labelptbr, image, type!inner(id, descriptionenus), category(id, descriptionptbr)' :
          'id, labelenus, image, type!inner(id, descriptionenus), category(id, descriptionenus)')
        .eq('type.descriptionenus', type);

      if (searchText && searchText !== '')
        if (isBrazilianPortuguese)
          execution.like('labelptbr', '%' + searchText + '%')
        else
          execution.like('labelenus', '%' + searchText + '%')
      
      execution
        .range(skip, skip + (take - 1))
        .order('id', { ascending: false })
        .returns<ProductBasics[]>()
        .then(response => {
          resolve(response.data ?? []);
        });
    });
  }

  async function getTotalNumberOfProducts(type: string, isBrazilianPortuguese: boolean, searchText: string): Promise<number> {
    return new Promise((resolve) => {
      let execution = supabase
        .from("product")
        .select('id, type(descriptionenus)')
        .filter('type.descriptionenus', 'eq', type);

      if (searchText && searchText !== '')
        if (isBrazilianPortuguese)
          execution.like('labelptbr', '%' + searchText + '%')
        else
          execution.like('labelenus', '%' + searchText + '%')

      execution
        .returns<ProductBasics[]>()
        .then(response => {
          resolve(response.data ? response.data?.filter(p => p.type).length : 0);
        });
    });
  }

  async function getProductById(id: number, isBrazilianPortuguese: boolean): Promise<Product | null> {
    return new Promise((resolve) => {
      supabase
        .from('product')
        .select(isBrazilianPortuguese ?
          'id, labelptbr, synopsisptbr, image, magnetlink720p, magnetlink1080p, magnetlink2160p, year, imdb, type(descriptionptbr), category(descriptionptbr)' :
          'id, labelenus, synopsisenus, image, magnetlink720p, magnetlink1080p, magnetlink2160p, year, imdb, type(descriptionenus), category(descriptionenus)')
        .filter('id', 'eq', id)
        .returns<Product[]>()
        .then(response => {
          resolve(response.status === 200 && response.data ? response.data[0] as Product : null);
        });
    });
  }

  return { getMovies, getPCGames, getTvShows, getTotalNumberOfProducts, getProductById };
}