import Catalog from '../../components/catalog';
import Header from '../../components/header';
import './styles.css';
import { useAdTimer } from '../../hooks/useAdTimer';
import { useEffect } from 'react';

function Main() {
  const { trigger } = useAdTimer();

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <div>
      <Header />
      <Catalog />
    </div>
  );
}

export default Main;
