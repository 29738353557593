import './styles.css';

function AdBlockDetected() {
  return (
    <div className='adblock-screen'>
      {
      navigator.language === 'pt-BR'?
      <>
        <div>
          <h1>AdBlock detectado!</h1>
          <p>Por favor, inative o AdBlock para que possamos continuar fornecendo um serviço gratis e de qualidade.</p>
          <p>Sem o ganho monetário retornado pelos anúncios, não conseguimos seguir dando suporte ao web site :(</p>
        </div>
      </>
      :
      <>
        <div>
          <h1>AdBlock detected!</h1>
          <p>Please disable AdBlock so we can continue providing a free and quality service.</p>
          <p>Without the monetary gain from ads, we can't continue supporting the website :(</p>
        </div>
      </>
      }
    </div>
  );
}

export default AdBlockDetected;