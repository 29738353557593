import { useState } from 'react';
import logo from '../../assets/logo.png';
import './styles.css';
import { BsSearch } from "react-icons/bs";
import { useSearch } from '../../hooks/useSearch';

function Header() {
  const { search } = useSearch();
  const [text, setText] = useState<string>('');

  return (
    <div>
      <header>
        <div className='header'>
          <div className='title'>
            <img src={logo} className="logo" alt="logo" />
            <h3>TorrentDownloads.online</h3>
          </div>
          <div>
            <input id='search' type="text" className='search-input' placeholder={navigator.language === 'pt-BR' ? 'Buscar' : 'Search...'} onChange={(e) => setText(e.target.value)} />
            <BsSearch className='search-icon' color='white' onClick={() => search(text)} />
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
