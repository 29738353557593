import { ProductBasics } from '../../interfaces/product';
import './styles.css';
import { useNavigate } from "react-router-dom";

interface Props {
  product: ProductBasics
}

function CatalogSquare({ product }: Props) {
  const navigate = useNavigate();
  
  function OpenDetails() {
    navigate('/details/' + product.id);
  }

  return (
    <div className='showcase-square' onClick={() => OpenDetails()}>
      <img className='poster' src={product.image} alt="Poster" />
      <p>{product.labelptbr ?? product.labelenus}</p>
      <div className='categories'>
        {
          product?.category.map((cat) =>
            <span key={cat.descriptionptbr ?? cat.descriptionenus}>{cat.descriptionptbr ?? cat.descriptionenus}</span>
          )
        }
      </div>
    </div>
  );
}

export default CatalogSquare;
