import React from 'react';
import { Route, Routes, HashRouter} from 'react-router-dom';

import Main from './pages/Main';
import Details from './pages/Details';
import AdBlockDetected from './pages/AdBlockDetected';

import { SearchProvider } from './hooks/useSearch';
import { AdTimerProvider } from './hooks/useAdTimer';

import { useDetectAdBlock } from "adblock-detect-react";

const Router: React.FC = () => {
  const adBlockDetected = useDetectAdBlock();

  return (
    <HashRouter>
      <SearchProvider>
        <AdTimerProvider>
          <Routes>
            <Route Component={adBlockDetected ? AdBlockDetected : Main} path="/" />
            <Route Component={adBlockDetected ? AdBlockDetected : Main} path="/home" />
            <Route Component={adBlockDetected ? AdBlockDetected : Details} path="/details/:id" />
          </Routes>
        </AdTimerProvider>
      </SearchProvider>
    </HashRouter>
  );
};

export default Router;