import { ReactNode, createContext, useContext, useState } from "react";

interface AdTimerProviderProps {
  children: ReactNode;
}

interface AdTimerContextData {
  trigger: () => Promise<void>;
}

const AdTimerContext = createContext<AdTimerContextData>({} as AdTimerContextData);

export function AdTimerProvider({ children }: AdTimerProviderProps): JSX.Element {
  const [isRunning, setIsRunning] = useState<boolean>(false);

  const trigger = async () => {
    if (!isRunning) {
      setIsRunning(true);
      while(true) {
        await new Promise(resolve => setTimeout(resolve, 20000));
        window.open(process.env.REACT_APP_MODAL_AD_LINK, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
      }
    }
  }

  return (
    <AdTimerContext.Provider value={{ trigger }}>
      {children}
    </AdTimerContext.Provider>
  );
}

export function useAdTimer(): AdTimerContextData {
  const context = useContext(AdTimerContext);

  return context;
}